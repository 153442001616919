import * as Ably from "ably";
import { AblyProvider } from "ably/react";
import LogRocket from "logrocket";
import { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";
import AppRoutes from "./Routes";
import { ablyToken } from "./api/auth";
import NeedChrome from "./components/NeedChrome";
import SplashScreen from "./components/SplashScreen";
import { logrocket as lrconfig } from "./config";
import { useAuth } from "./contexts/AuthContext";

const ablyClient = Ably.Realtime.Promise({
  authCallback: async (data, callback) => {
    try {
      const tokenRequest = await ablyToken();
      callback(null, tokenRequest.data);
    } catch (e) {
      callback(e, null);
    }
  },
})

function App() {
  const { apiUser } = useAuth();
  const [isChrome, setIsChrome] = useState();
  const [lrInit, setLrInit] = useState(false);
  const [continueAnyway, setContinueAnyway] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const _isChrome = !!window.chrome;
    setIsChrome(_isChrome);
    if (_isChrome) {
      Notification.requestPermission();
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (lrconfig !== undefined) {
      LogRocket.init(lrconfig, {
        network: {
          requestSanitizer: (request) => {
            //request.body = null;
            return request;
          },
        },
      });
      setLrInit(true);
    }
  }, []);

  useEffect(() => {
    if (lrconfig !== undefined && apiUser && lrInit) {
      LogRocket.identify(apiUser.email, {
        name: `${apiUser.first_name} ${apiUser.last_name}`,
        // Add your own custom user variables here, ie:
        email: apiUser.email,
      });
    }
  }, [lrInit, apiUser]);

  if (loading) return <SplashScreen />;

  return isChrome || continueAnyway ? (
    <AblyWrapper>
      <AppRoutes />
    </AblyWrapper>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <NeedChrome />
      <Button primary onClick={() => setContinueAnyway(true)}>
        Continue Anyway
      </Button>
    </div>
  );
}

function AblyWrapper({ children }) {
  const { apiUser } = useAuth();

  if (!apiUser?.has_ably) return children;

  return (
    <AblyProvider client={ablyClient}>
      {children}
    </AblyProvider>
  );
}

export default App;
