import { useInfiniteQuery } from "@tanstack/react-query";
import { getWhatsappThreads } from "../api/whatsapp";

const useWhatsappThreads = ({ enabled = false }) => {
  return useInfiniteQuery({
    queryKey: ["whatsapp-threads"],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await getWhatsappThreads({
        page: pageParam,
      });
      return res;
    },
    getNextPageParam: (lastPage) =>
      lastPage.data.meta.current_page < lastPage.data.meta.last_page
        ? lastPage.data.meta.current_page + 1
        : undefined,
    keepPreviousData: true,
    cacheTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: enabled,
  });
};

export default useWhatsappThreads;
