import { Button, Form, Input } from "semantic-ui-react";
import YesNoToggle from "./YesNoToggle";

function MohDetails({ submitting, moh, onChange, onSubmit, onClickBack }) {
  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Field>
          <label>Is this a MoH order</label>
          <YesNoToggle value={moh.required} onChange={onChange} />
        </Form.Field>
        {moh.required && (
          <>
            <Form.Field>
              <label>Email address</label>
              <p>The email address of the customer's MoH user</p>
              <Input
                type="email"
                name="admin_email"
                onChange={onChange}
                value={moh.admin_email}
              />
            </Form.Field>
          </>
        )}

        <Button.Group>
          <Button onClick={onClickBack}>Back</Button>
          <Button.Or />
          <Button loading={submitting} primary>
            Next
          </Button>
        </Button.Group>
      </Form>
    </>
  );
}

export default MohDetails;
