import { useParams } from "react-router-dom";
import { Button, Form, Icon, TextArea } from "semantic-ui-react";
import { useSendMediaMessage } from "../../hooks/useSendMediaMessage";
import EmojiPicker from "./EmojiPicker";
import { style } from "./style";

export function ReplyField({
  children,
  color,
  handleSubmit,
  features,
  message,
  sending,
  disabled,
  setMessage,
}) {
  const { id } = useParams();
  const { sendWhatsappMedia, isSending } = useSendMediaMessage(id);

  const hasMessagingFeature = (feature) => features?.includes(feature);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      if (disabled?.status || sending || isSending || message.length === 0)
        return;
      handleSubmit(e);
    }
  };

  return (
    <div style={style.reply.container}>
      <Form onSubmit={handleSubmit} style={style.reply.form}>
        <TextArea
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a new message"
          value={message}
          onKeyDown={handleKeyDown}
          style={style.reply.textArea}
        />
        <div style={style.reply.buttonContainer}>
          {disabled?.status && (
            <div style={{ marginRight: "15px", color: "grey" }}>
              {disabled?.message}
            </div>
          )}
          {hasMessagingFeature("emoji") && !disabled?.status && (
            <EmojiPicker setMessage={setMessage} />
          )}
          {hasMessagingFeature("media") && !disabled?.status && (
            <>
              <input
                type="file"
                id="file"
                onChange={(e) => sendWhatsappMedia(e)}
                hidden
              />
              <label style={style.reply.actionButton} htmlFor="file">
                <Icon link name="attach" size="large" />
              </label>
            </>
          )}
          {children}
          <Button
            className={color}
            icon
            loading={sending || isSending}
            disabled={
              disabled?.status || sending || isSending || message.length === 0
            }
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <Icon name="send" style={{ pointerEvents: "none" }} />
          </Button>
        </div>
      </Form>
    </div>
  );
}
