import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Button, Form, Header, Icon, Input, Popup } from "semantic-ui-react";
import styled from "styled-components";
import { getInsights } from "../api/insights";
import CallLogs from "../components/CallLogs";
import DialPad from "../components/DialPad";
import SideScrollerLayout, {
  SideScrollerContent,
  SideScrollerSidebar,
} from "../components/SideScrollerLayout";
import StatCard from "../components/StatCard";
import { useAuth } from "../contexts/AuthContext";
import { useCalls } from "../contexts/CallsContext";
import { useUserClis } from "../contexts/UserClisContext";
import devices from "../layout/devices";
import { colours } from "./Insights";
import { Select } from "semantic-ui-react";

const Content = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  height: calc(100vh - 60px);
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 6vh;

  @media ${devices.laptopL} {
    padding-top: 20vh;
  }
`;

const style = {
  dialPadContainer: {
    margin: "auto",
    textAlign: "center",
    width: "245px",
  },
  dialPadInput: {
    marginBottom: "12px",
    height: "46px",
  },
  dialPadCall: {
    marginTop: "12px",
    marginBottom: "12px",
    height: "46px",
  },
  unparkCall: {
    marginBottom: "12px",
  },
};

function Calls() {
  const [destinationNumber, setDestinationNumber] = useState("");
  const { hasFeature } = useAuth();
  const { userClis, selectedCli, setSelectedCli } = useUserClis();

  const { isPhoneControl } = useAuth();
  const { handleMakeCall, handleUnparkCall } = useCalls();

  const handleDialPad = (value) => {
    setDestinationNumber(destinationNumber + value);
  };

  return (
    <SideScrollerLayout>
      <SideScrollerSidebar>
        <Header style={{ padding: "17px 0px 0px 17px" }}>Call History</Header>
        <CallLogs />
      </SideScrollerSidebar>

      <SideScrollerContent>
        <Content>
          <div>
            <Form
              style={style.dialPadContainer}
              onSubmit={() => {
                handleMakeCall(destinationNumber, "audio");
                setDestinationNumber("");
              }}
            >
              {userClis.length > 0 && hasFeature("cli_selection") && (
                <Form.Field>
                  <label for="cli">Outbound CLI</label>
                  <Select
                    placeholder="User default"
                    name="cli"
                    value={selectedCli}
                    onChange={(ev, el) => {
                      setSelectedCli(el.value);
                    }}
                    options={[
                      {
                        key: null,
                        value: null,
                        text: "User default",
                      },
                      ...userClis.map((c) => ({
                        key: c.id,
                        value: c.id,
                        text:
                          c.cliNumber + (c.cliLabel ? ` (${c.cliLabel})` : ""),
                      })),
                    ]}
                  />
                </Form.Field>
              )}

              <Input
                placeholder="Dial"
                fluid
                autoFocus
                value={destinationNumber}
                onChange={(e) =>
                  setDestinationNumber(e.target.value.replace(/\D/g, ""))
                }
                action={
                  <Button
                    icon
                    type="button"
                    color="teal"
                    disabled={!destinationNumber}
                    onClick={() => {
                      handleMakeCall(destinationNumber, "audio");
                      setDestinationNumber("");
                    }}
                  >
                    <Icon name="phone" />
                  </Button>
                }
                style={style.dialPadInput}
              />

              <DialPad onClick={handleDialPad} />

              <Button
                fluid
                type="submit"
                style={style.dialPadCall}
                disabled={destinationNumber.length === 0}
                color="teal"
                icon="phone"
              />
            </Form>

            {!isPhoneControl && (
              <Popup
                position="bottom center"
                disabled={destinationNumber.length === 3}
                content={"Park locations are 3 digits long"}
                trigger={
                  <span>
                    <Button
                      fluid
                      basic
                      style={style.unparkCall}
                      disabled={destinationNumber.length !== 3}
                      primary
                      onClick={() => handleUnparkCall(destinationNumber)}
                    >
                      <Icon name="phone" />
                      Unpark Call
                    </Button>
                  </span>
                }
              />
            )}

            <Voicemail />
          </div>

          {hasFeature(["insights"]) && (
            <div>
              <Insights />
            </div>
          )}
        </Content>
      </SideScrollerContent>
    </SideScrollerLayout>
  );
}

function Insights() {
  const insightsQuery = useQuery(
    ["insights"],
    () => {
      return getInsights();
    },
    {
      keepPreviousData: true,
      cacheTime: 3600000, // one hour
      staleTime: 600000, // 10 mins
    },
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "15px",
        flexWrap: "wrap",
        justifyContent: "center",
        margin: "40px",
      }}
    >
      <div>
        <StatCard
          label="Total calls"
          value={insightsQuery.data?.data?.data.calls.count}
          colour={colours.purple}
          loading={insightsQuery.isFetching}
        />
        <StatCard
          label="Average Talk Time"
          value={insightsQuery.data?.data?.data.calls.average_duration}
          colour={colours.purple}
          loading={insightsQuery.isFetching}
        />
        <StatCard
          label="Total Talk Time"
          value={insightsQuery.data?.data?.data.calls.total_duration}
          colour={colours.purple}
          loading={insightsQuery.isFetching}
        />
      </div>
      <div>
        <StatCard
          label="Recent Outgoing Calls"
          value={insightsQuery.data?.data?.data.calls.outgoing.count}
          colour={colours.violet}
          loading={insightsQuery.isFetching}
        />
        <StatCard
          label=""
          value={insightsQuery.data?.data?.data.calls.outgoing.split_percent}
          colour={colours.violet}
          loading={insightsQuery.isFetching}
        />
        <StatCard
          label="Recent Incoming Calls"
          value={insightsQuery.data?.data?.data.calls.incoming.count}
          colour={colours.teal}
          loading={insightsQuery.isFetching}
        />
        <StatCard
          label=" "
          value={insightsQuery.data?.data?.data.calls.incoming.split_percent}
          colour={colours.teal}
          loading={insightsQuery.isFetching}
        />
        <p>
          {!insightsQuery.isFetching && (
            <small>
              Last refreshed at {insightsQuery.data?.data?.data.refreshed_at}
            </small>
          )}
        </p>
      </div>
    </div>
  );
}

function Voicemail() {
  const { vmInfo, getVmLink } = useCalls();

  const [openingVm, setOpeningVm] = useState(false);

  const handleClick = async () => {
    setOpeningVm(true);
    const res = await getVmLink();
    await getVmLink();
    setOpeningVm(false);
    window.open(res.vmDesktopWeb);
  };

  if (!vmInfo) return null;

  return (
    <Button
      fluid
      color="blue"
      basic={vmInfo?.newMsg === 0}
      onClick={handleClick}
      disabled={openingVm}
      loading={openingVm}
    >
      <Icon name="film" />{" "}
      {vmInfo?.newMsg > 0
        ? `${vmInfo.newMsg} new VM messages`
        : "Access Voicemail"}
    </Button>
  );
}

export default Calls;
