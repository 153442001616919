import { sdk } from "@gc/ipecs-web-sdk";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

const ChatContext = React.createContext();

const ChatProvider = ({ children }) => {
  const { room } = sdk;
  const { hasFeature } = useAuth();
  const location = useLocation();
  const [rooms, setRooms] = useState([]);
  const [lastMessageReceived, setLastMessageReceived] = useState();

  const fetchRooms = useCallback(async () => {
    if (!hasFeature(["chat"])) return;

    try {
      let _rooms = (await room.getRoomList({ type: "Chat" }))
        .sort((a, b) => b.message.timestamp - a.message.timestamp)
        .filter((x) => x.displayName);

      if (location.pathname === `/chat/${lastMessageReceived?.id}`) {
        setRooms(
          _rooms.map((x) => ({
            ...x,
            unReadCount: x.id === lastMessageReceived.id ? 0 : x.unReadCount,
          })),
        );
      } else {
        setRooms(_rooms);
      }
    } catch (error) {
      console.error("Failed to fetch rooms:", error);
    }
  }, [room, hasFeature, location.pathname, lastMessageReceived]);

  useEffect(() => {
    fetchRooms();
  }, [fetchRooms]);

  useEffect(() => {
    room.onReceiveMessage(async ({ id, message }) => {
      if (hasFeature(["chat"])) {
        setLastMessageReceived({ id, message });

        if (message.type === "m.room.message.text" && !message.byMe) {
          const notification = new Notification(`New message`, {
            body: `From ${message.senderName}`,
            icon: "https://connect.cosoft.co.uk/favicon-32x32.png",
          });
          notification.onclick = () => window.focus();
        }
      }
    });
  }, [room, hasFeature]);

  const getTotalUnread = useCallback(() => {
    return rooms.reduce(
      (previousValue, room) => previousValue + (room.unReadCount || 0),
      0,
    );
  }, [rooms]);

  const defaultContext = {
    rooms,
    getTotalUnread,
    lastMessageReceived,
  };
  return (
    <ChatContext.Provider value={defaultContext}>
      {children}
    </ChatContext.Provider>
  );
};

function useChat() {
  return useContext(ChatContext);
}

export { ChatProvider, useChat };
