import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useChat } from "../contexts/ChatContext";
import useEngageThreads from "./useEngageThreads";
import useWhatsappThreads from "./useWhatsappThreads";

const useMessages = () => {
  const { apiUser, hasFeature } = useAuth();
  const chatContext = useChat();
  const { rooms = [], getTotalUnread = () => 0 } = chatContext || {};
  const [combinedThreads, setCombinedThreads] = useState([]);

  const hasChatFeature = Boolean(hasFeature(["chat"]));
  const hasWhatsappFeature = Boolean(hasFeature(["whatsapp"]));
  const hasEngageFeature = Boolean(apiUser.engage_id);
  const hasAnyMessageFeatures =
    hasChatFeature || hasWhatsappFeature || hasEngageFeature;

  const whatsappThreadsQuery = useWhatsappThreads({
    enabled: hasWhatsappFeature,
  });

  const engageThreadsQuery = useEngageThreads({
    enabled: hasEngageFeature,
  });

  useEffect(() => {
    if (!hasAnyMessageFeatures) return;
    if (hasChatFeature && !rooms) return;
    if (hasWhatsappFeature && !whatsappThreadsQuery.data) return;
    if (hasEngageFeature && !engageThreadsQuery.data) return;

    const chatThreads =
      hasChatFeature && rooms.length
        ? rooms
            .filter((x) => x.type === "Chat")
            .map((room) => ({
              id: room.id,
              type: "chat",
              chatUrl: `/messages/chat/${room.id}`,
              color: "chat",
              icon: "chat",
              hasUnread: room.unReadCount > 0,
              avatar: room.avatars[0],
              displayName: room.displayName,
              timestamp: room.message.timestamp,
              message: room.message.value,
              label: null,
            }))
        : [];

    const whatsappThreads =
      hasWhatsappFeature && whatsappThreadsQuery.data?.pages
        ? whatsappThreadsQuery.data.pages.flatMap((page) =>
            page.data.data.map((thread) => ({
              id: thread.sqid,
              type: "whatsapp",
              chatUrl: `/messages/whatsapp/${thread.sqid}`,
              color: "whatsapp",
              icon: "whatsapp",
              hasUnread: thread.has_unread,
              avatar: null,
              displayName: thread.sender_name
                ? `${thread.sender_name} (${thread.provider_id})`
                : thread.provider_id,
              timestamp: new Date(thread.updated_at).getTime(),
              message: thread.latest_message,
              label: thread?.to_phone_number || null,
            })),
          )
        : [];

    const engageThreads =
      hasEngageFeature && engageThreadsQuery.data?.pages
        ? engageThreadsQuery.data.pages.flatMap((page) =>
            page.data.data.map((thread) => ({
              id: thread.id,
              type: "engage",
              chatUrl: `/messages/engage/${thread.id}`,
              color: "engage",
              icon: "engage",
              hasUnread: thread.has_unread,
              avatar: null,
              displayName: thread.name,
              timestamp: new Date(thread.last_message_millis).getTime(),
              message: null,
              label: null,
            })),
          )
        : [];

    const combined = [
      ...chatThreads,
      ...whatsappThreads,
      ...engageThreads,
    ].sort((a, b) => b.timestamp - a.timestamp);

    setCombinedThreads(combined);
  }, [
    rooms,
    whatsappThreadsQuery.data,
    engageThreadsQuery.data,
    hasAnyMessageFeatures,
    hasChatFeature,
    hasEngageFeature,
    hasWhatsappFeature,
  ]);

  const hasWhatsappUnread =
    hasWhatsappFeature &&
    whatsappThreadsQuery?.data?.pages[0]?.data?.meta?.has_unread;
  const hasEngageUnread =
    hasEngageFeature &&
    engageThreadsQuery?.data?.pages[0]?.data?.meta?.has_unread;
  const hasUnreadChats = hasChatFeature && getTotalUnread() > 0;
  const hasAnyUnreadMessages =
    hasWhatsappUnread || hasUnreadChats || hasEngageUnread;

  const isLoading =
    (hasWhatsappFeature && whatsappThreadsQuery.isLoading) ||
    (hasEngageFeature && engageThreadsQuery.isLoading);

  const isLoadingNextPage =
    (hasWhatsappFeature && whatsappThreadsQuery.isFetchingNextPage) ||
    (hasEngageFeature && engageThreadsQuery.isFetchingNextPage);

  const hasMorePages =
    (hasWhatsappFeature &&
      whatsappThreadsQuery.data?.pages?.length > 0 &&
      whatsappThreadsQuery.hasNextPage) ||
    (hasEngageFeature &&
      engageThreadsQuery.data?.pages?.length > 0 &&
      engageThreadsQuery.hasNextPage) ||
    false;

  const fetchNextPage = () => {
    if (hasWhatsappFeature && whatsappThreadsQuery.hasNextPage) {
      whatsappThreadsQuery.fetchNextPage();
    }
    if (hasEngageFeature && engageThreadsQuery.hasNextPage) {
      engageThreadsQuery.fetchNextPage();
    }
  };

  const getMostRecentThread = () => {
    return combinedThreads[0] || null;
  };

  return {
    combinedThreads,
    getMostRecentThread,
    hasAnyMessageFeatures,
    hasAnyUnreadMessages,
    hasEngageFeature,
    hasMorePages,
    hasUnreadChats,
    hasWhatsappFeature,
    hasWhatsappUnread,
    fetchNextPage,
    isLoading,
    isLoadingNextPage,
  };
};

export default useMessages;
