import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { csrfCookie, getMe } from "../api/auth";
import SplashScreen from "../components/SplashScreen";
import { storageKeys } from "../constants";

const AuthContext = React.createContext();
const AuthProvider = ({ children }) => {
  const [apiUser, setApiUser] = useState();
  const [ipecsAuthentciated, setIpecsAuthenticated] = useState(false);
  const [ipecsUser, setIpecsUser] = useState();
  const [ipecsAuthData, setIpecsAuthData] = useState();
  const [loggingOut, setLoggingOut] = useState(false);
  const [isPhoneControl, setIsPhoneControl] = useState();
  const [isAcd, setIsAcd] = useState(
    localStorage.getItem(storageKeys.ACD_ENABLED) ?? false,
  );
  const [tokenReissued, setTokenReissued] = useState(false);

  useEffect(() => {
    csrfCookie();
  }, []);

  const meQuery = useQuery({
    queryKey: ["me"],
    queryFn: getMe,
    retry: false,
    onError: () => setApiUser(),
  });

  useEffect(() => {
    if (meQuery?.data) {
      setApiUser(meQuery.data.data.data);
      setIsPhoneControl(meQuery.data.data.data.phone_control ?? false);
    }
  }, [meQuery.data]);

  useEffect(() => {
    if (
      apiUser &&
      apiUser.hasOwnProperty("notifications") &&
      Array.isArray(apiUser.notifications)
    ) {
      apiUser.notifications.map((x) => {
        return toast(
          <div dangerouslySetInnerHTML={{ __html: x.message }}></div>,
          {
            type: x.type,
            autoClose: x.auto_close,
          },
        );
      });
    }
  }, [apiUser]);

  const hasFeature = (features) => {
    return (
      apiUser &&
      apiUser.features &&
      apiUser.features.length > 0 &&
      apiUser.features.some((r) => features.includes(r))
    );
  };

  const hasIntegration = (integrations) => {
    return (
      apiUser &&
      apiUser.integrations &&
      apiUser.integrations.length > 0 &&
      apiUser.integrations.some((r) => integrations.includes(r))
    );
  };

  const defaultContext = {
    apiUser,
    setApiUser,
    ipecsUser,
    ipecsAuthData,
    isPhoneControl,
    isAcd,
    ipecsAuthentciated,
    tokenReissued,
    setIpecsAuthenticated,
    setIpecsUser,
    setIpecsAuthData,
    setLoggingOut,
    setIsPhoneControl,
    setIsAcd,
    setTokenReissued,
    hasFeature,
    hasIntegration,
  };
  return (
    <AuthContext.Provider value={defaultContext}>
      {meQuery.isLoading || loggingOut ? <SplashScreen /> : <>{children}</>}
    </AuthContext.Provider>
  );
};

function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };
