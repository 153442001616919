import { Navigate } from "react-router-dom";
import MessagesContainer from "../components/messaging/MessagesContainer";
import PageLoader from "../components/PageLoader";
import useMessages from "../hooks/useMessages";

function Messages() {
  const { getMostRecentThread, isLoading } = useMessages();

  if (isLoading) {
    return <PageLoader>Loading messages...</PageLoader>;
  }

  const mostRecentThread = getMostRecentThread();
  if (mostRecentThread) {
    return <Navigate to={mostRecentThread.chatUrl} />;
  }

  return <MessagesContainer />;
}

export default Messages;
