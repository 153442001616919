import { sdk } from "@gc/ipecs-web-sdk";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import call1Animation from "../animations/call1.json";
import { postCallAnswered, postCallEnded, postCallStarted } from "../api/calls";
import { lookupByCallerid } from "../api/contacts";
import ActivePhoneControlCall from "../components/phoneControl/ActivePhoneControlCall";
import IncomingPhoneControlCallAlert from "../components/phoneControl/IncomingPhoneControlCallAlert";
import { closePopupDialler, focusIncomingCall } from "../helpers/clickToDial";
import { convertToE164 } from "../helpers/convertToE164";
import { useAuth } from "./AuthContext";
import { useCallLogs } from "./CallLogsContext";
import { useCallData } from "./CallDataContext";

const callAnimations = [call1Animation];

const PhoneControlContext = React.createContext();
const PhoneControlProvider = ({ children }) => {
  const { phoneControl } = sdk;
  const { refetchCallLogs } = useCallLogs();
  const { apiUser } = useAuth();
  const { currentCallMeta } = useCallData();

  const [minimized, setMinimized] = useState(false);
  const [disconnecting, setDisconnecting] = useState(false);
  const [numberInvalid, setNumberInvalid] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [activeCall, setActiveCall] = useState();
  const [consultationCall, setConsultationCall] = useState();
  const [activeCallParties, setActiveCallParties] = useState([]);
  const [incomingCall, setIncomingCall] = useState();
  const [callDirection, setCallDirection] = useState();
  const [callDuration, setCallDuration] = useState();
  const [blindTransferring, setBlindTransferring] = useState(false);
  const [transferNumber, setTransferNumber] = useState("");

  const [eventCallAlert, setEventCallAlert] = useState();
  const [eventCallEnded, setEventCallEnded] = useState();
  const [eventCallConnected, setEventCallConnected] = useState();
  const [eventCallReceived, setEventCallReceived] = useState();
  const [eventTransferToReceived, setEventTransferToReceived] = useState();
  const [
    eventPhoneControlCallConferenceMembers,
    setEventPhoneControlCallConferenceMembers,
  ] = useState();
  const [
    eventPhoneControlCallConferenced,
    setEventPhoneControlCallConferenced,
  ] = useState();

  const [
    eventPhoneControlCallReleasedAsc,
    setEventPhoneControlCallReleasedAsc,
  ] = useState();

  const callAnimationRef = useRef();

  const handleCloseOverlay = useCallback(() => {
    setIncomingCall();
    setConnecting(false);
    setNumberInvalid(false);
    setTransferNumber("");
    setBlindTransferring(false);
    refetchCallLogs();
    closePopupDialler();
  }, [refetchCallLogs]);

  const handleCallAnswered = useCallback(async () => {
    try {
      await postCallAnswered({
        direction: callDirection,
        number: activeCall.callNumber,
      });
    } catch {
      // do nothing
    }
  }, [callDirection, activeCall]);

  const handleCallEnded = useCallback(async () => {
    try {
      if (activeCall) {
        await postCallEnded({
          provider_id: activeCall.ticket,
          duration: callDuration,
          direction: callDirection,
          number: activeCall.callNumber,
          metadata: currentCallMeta,
        });
      }
    } catch {
      // do nothing
    } finally {
      setActiveCallParties([]);
      setActiveCall();
      setCallDirection();
      setCallDuration();
    }
  }, [callDirection, callDuration, activeCall, currentCallMeta]);

  // prevent refresh if call is ongoing
  useEffect(() => {
    window.onbeforeunload = function () {
      if (activeCall) return false;
      // else do nothing
    };
    console.log("active call:");
    console.log(activeCall);
  }, [activeCall]);
  // ensure call parties are always set correctly
  // clear them if there is no active call
  useEffect(() => {
    const retrieveCallInfo = async () => {
      // setting conference call members is handled by a separate event - this check prevents this request from overriding it
      if (activeCall.consultationType !== "conference") {
        try {
          const callPartyInfo =
            await phoneControl.phoneControlGetOtherPartyInfo({
              ticket: activeCall.ticket,
              peerId: activeCall.peerId,
            });
          setActiveCallParties([callPartyInfo]);
        } catch (e) {
          const callPartyInfo = {
            name: activeCall.callNumber,
            callNumber: activeCall.callNumber,
            peerId: activeCall.callPeerId,
          };
          setActiveCallParties([callPartyInfo]);
        }
      }
    };

    if (activeCall) {
      // conference call party info is handled by a different event ()
      if (activeCall.consultationType !== "mainConference") {
        if (activeCall.state === "answered" || activeCall.state === "calling") {
          retrieveCallInfo();
        }
      }
    } else {
      setActiveCallParties([]);
    }
  }, [activeCall, phoneControl]);

  // setup event listeners
  useEffect(() => {
    phoneControl.onPhoneControlCallAlert((data) => {
      setEventCallAlert(data);
    });

    phoneControl.onPhoneControlCallConferenceMembers(
      setEventPhoneControlCallConferenceMembers,
    );

    phoneControl.onPhoneControlCallConferenced(
      setEventPhoneControlCallConferenced,
    );

    phoneControl.onPhoneControlCallReleasedAsc(
      setEventPhoneControlCallReleasedAsc,
    );

    phoneControl.onPhoneControlCallEnded(({ ticket }) => {
      setEventCallEnded(ticket);
    });

    phoneControl.onPhoneControlCallConnected(({ ticket }) => {
      setEventCallConnected(ticket);
    });

    phoneControl.onPhoneControlCallReceived((event) => {
      setEventCallReceived(event);
    });

    phoneControl.onPhoneControlCallTransferred((event) => {
      setEventTransferToReceived(event);
    });
  }, [phoneControl]);

  // handle call alert
  useEffect(() => {
    if (eventCallAlert) {
      !transferNumber
        ? setActiveCall(eventCallAlert)
        : setConsultationCall(eventCallAlert);

      if (eventCallAlert?.ticket && eventCallAlert?.peerId) {
        phoneControl
          .phoneControlGetOtherPartyInfo({
            ticket: eventCallAlert.ticket,
            peerId: eventCallAlert.peerId,
          })
          .then((res) =>
            setActiveCallParties((callParties) => [...callParties, res]),
          );
      }

      setEventCallAlert();
    }
  }, [phoneControl, eventCallAlert, transferNumber]);

  // handle call ended
  useEffect(() => {
    if (eventCallEnded) {
      if (!activeCall && !consultationCall) {
        handleCloseOverlay();
        setEventCallEnded();
        handleCallEnded();
      }

      if (consultationCall?.ticket === eventCallEnded) {
        setTransferNumber("");
        setConsultationCall();
      }

      if (activeCall?.ticket === eventCallEnded && consultationCall) {
        setActiveCall(consultationCall);
        setConsultationCall();
        setTransferNumber("");
      }

      if (activeCall?.ticket === eventCallEnded && !consultationCall) {
        handleCloseOverlay();
        setEventCallEnded();
        handleCallEnded();
      }
    }
  }, [
    eventCallEnded,
    activeCall,
    consultationCall,
    handleCloseOverlay,
    handleCallEnded,
  ]);

  useEffect(() => {
    if (eventPhoneControlCallConferenced) {
      setActiveCallParties(
        eventPhoneControlCallConferenced.confMembers
          .filter((u) => !u.isMe)
          .map((u) => {
            return {
              ...u,
              peerId: u.userType === "external" ? "EX_" + u.number : u.userId,
              callNumber: u.userType === "external" ? u.number : null,
            };
          }),
      );

      setEventPhoneControlCallConferenced();
    }
  }, [eventPhoneControlCallConferenced, setEventPhoneControlCallConferenced]);

  useEffect(() => {
    if (eventPhoneControlCallReleasedAsc) {
      if (eventPhoneControlCallReleasedAsc.newPeerId) {
        setActiveCallParties((callParties) => {
          return [
            ...callParties.filter(function (p) {
              return p.peerId === eventPhoneControlCallReleasedAsc.newPeerId;
            }),
          ];
        });
      }

      setEventPhoneControlCallReleasedAsc();
    }
  }, [eventPhoneControlCallReleasedAsc, setEventPhoneControlCallReleasedAsc]);

  useEffect(() => {
    if (eventPhoneControlCallConferenceMembers) {
      setActiveCallParties(
        eventPhoneControlCallConferenceMembers.callUserInfo
          .filter((u) => !u.isMe)
          .map((u) => {
            return {
              ...u,
              peerId: u.userType === "external" ? "EX_" + u.number : u.userId,
              callNumber: u.userType === "external" ? u.number : null,
            };
          }),
      );
      setEventPhoneControlCallConferenceMembers();
    }
  }, [
    eventPhoneControlCallConferenceMembers,
    setEventPhoneControlCallConferenceMembers,
  ]);

  // handle call connected
  useEffect(() => {
    const handle = async () => {
      if (eventCallConnected) {
        if (consultationCall) {
          if (eventCallConnected === consultationCall.ticket) {
            setConsultationCall((consultationCall) => ({
              ...consultationCall,
              state: "answered",
            }));
          }
        }

        if (activeCall) {
          if (eventCallConnected === activeCall.ticket) {
            !transferNumber
              ? setActiveCall({ ...activeCall, state: "answered" })
              : setConsultationCall({ ...consultationCall, state: "answered" });
            setConnecting(false);
          }
          callAnimationRef.current =
            callAnimations[Math.floor(Math.random() * callAnimations.length)];
        } else if (incomingCall) {
          setActiveCall({ ...incomingCall, state: "answered" });
          setIncomingCall();
          // set a random animation
          callAnimationRef.current =
            callAnimations[Math.floor(Math.random() * callAnimations.length)];
        }
      }
    };

    handle();
    setEventCallConnected();
  }, [
    eventCallConnected,
    activeCall,
    consultationCall,
    transferNumber,
    incomingCall,
    phoneControl,
  ]);

  // handle incoming call received
  useEffect(() => {
    if (eventCallReceived) {
      if (!activeCall) {
        setCallDirection("INBOUND");
        setIncomingCall(eventCallReceived);

        const { ticket, callNumber, mediaType } = eventCallReceived;

        if (incomingCall?.ticket !== ticket) {
          phoneControl
            .phoneControlGetOtherPartyInfo({
              ticket: ticket,
              peerId: eventCallReceived.peerId,
            })
            .then((res) => {
              const notification = new Notification(
                `Incoming ${mediaType} call`,
                {
                  body: `From ${res.name}`,
                  icon: "https://connect.cosoft.co.uk/favicon-32x32.png",
                },
              );
              notification.onclick = () => window.focus();
            })
            .catch((e) => {
              const e164Number = convertToE164(
                callNumber,
                apiUser?.phone_locality,
              );

              lookupByCallerid(e164Number, 3000)
                .then((data) => {
                  const contact = data?.data?.data;
                  const contactName =
                    contact?.first_name || contact?.last_name
                      ? [contact?.first_name, contact?.last_name].join(" ")
                      : callNumber;

                  const notification = new Notification(`Incoming call`, {
                    body: `From ${contactName}`,
                    icon: "https://connect.cosoft.co.uk/favicon-32x32.png",
                  });
                  notification.onclick = () => window.focus();
                })
                .catch(() => {
                  const notification = new Notification(`Incoming call`, {
                    body: `From ${callNumber}`,
                    icon: "https://connect.cosoft.co.uk/favicon-32x32.png",
                  });
                  notification.onclick = () => window.focus();
                });
            });

          // Send a message to the Click to Dial extension to optionally focus an open Connect tab
          focusIncomingCall();
        }
      } else {
        console.log(
          "incoming call event recieved, but active call not cleared:",
        );
        console.log(activeCall);
      }

      setEventCallReceived();
    }
  }, [eventCallReceived, activeCall, incomingCall, apiUser, phoneControl]);

  const handleMakeCall = (destinationNumber, mediaType, delay = 0) => {
    setActiveCall({ state: "calling" });

    if (activeCall) {
      toast("You're already on a call", { type: "error" });
      return;
    }

    startCall(destinationNumber, mediaType, delay);
  };

  const startCall = useCallback(
    async (destinationNumber, mediaType, delay = 0) => {
      setCallDirection("OUTBOUND");
      const destinationNumberWithSuffix = apiUser?.dial_suffix
        ? destinationNumber + apiUser.dial_suffix
        : destinationNumber;

      try {
        if (destinationNumber.length > 4) {
          setMinimized(false);
        }

        // optionally delay sending the request to ipecs
        if (delay > 0) {
          await new Promise((r) => setTimeout(r, delay));
        }

        console.log("making call request (phone control mode)");

        const callInfo = await phoneControl.phoneCtrlMakeCall({
          destinationNumber: destinationNumberWithSuffix,
          mediaType: mediaType,
        });

        console.log(callInfo);

        // set a random animation
        callAnimationRef.current =
          callAnimations[Math.floor(Math.random() * callAnimations.length)];

        try {
          postCallStarted({
            direction: "OUTBOUND",
            number: destinationNumber,
          });
        } catch {
          // do nothing
        }

        try {
          const callPartyInfo =
            await phoneControl.phoneControlGetOtherPartyInfo({
              ticket: callInfo.ticket,
              peerId: callInfo.peerId,
            });
          setActiveCallParties((callParties) => [
            ...callParties,
            callPartyInfo,
          ]);
        } catch (e) {
          const callPartyInfo = {
            name: destinationNumber,
            callNumber: destinationNumber,
            peerId: callInfo.callPeerId,
          };
          setActiveCallParties((callParties) => [
            ...callParties,
            callPartyInfo,
          ]);
        }
      } catch (e) {
        switch (e.message) {
          case "E_CALL_PARAM_ERROR":
            toast(`Invalid number | ${destinationNumber}`, { type: "error" });
            setNumberInvalid(true);
            break;
          default:
            toast(`Failed to call | ${destinationNumber}`, { type: "error" });
        }

        setConnecting(false);
      }
    },
    [phoneControl, apiUser.dial_suffix],
  );

  const handleHangup = async () => {
    if (activeCall && !consultationCall) {
      setActiveCall((activeCall) => ({
        ...activeCall,
        state: "disconnecting",
      }));
      setActiveCallParties([]);
      try {
        await phoneControl.phoneCtrlEndCall(activeCall.ticket);
      } catch (e) {
        // do nothing
      }
      setDisconnecting(false);
      await handleCallEnded();

      handleCloseOverlay();
    }
  };

  const handleBlindTransfer = async (transferNumber) => {
    if (activeCall) {
      const { ticket } = activeCall;

      try {
        setBlindTransferring(true);
        await phoneControl.phoneCtrlBlindTransferCall({
          ticket,
          transferNumber,
        });
        handleCloseOverlay(ticket);
      } catch (e) {
        setBlindTransferring(false);
        toast("Failed to transfer call", { type: "error" });
      }
    }
  };

  const handleTransfer = async (transferNumber) => {
    if (activeCall) {
      const { ticket } = activeCall;

      try {
        setTransferNumber(transferNumber);
        const res = await phoneControl.phoneCtrlConsultationCall({
          consultationType: "transfer",
          ticket: ticket,
          number: transferNumber,
        });

        setConsultationCall(res);
      } catch (e) {
        console.log(e);
        setConsultationCall();
        setTransferNumber("");
        toast("Failed to transfer call", { type: "error" });
      }
    }
  };

  const handleTransferComplete = async () => {
    if (activeCall) {
      try {
        await phoneControl.phoneCtrlTransferCompleteReq(
          consultationCall.ticket,
        );
        handleCloseOverlay();
      } catch (e) {
        console.log(e);
        toast("Failed to transfer call", { type: "error" });
      }
    }
  };

  const handleTransferCancel = async () => {
    if (activeCall) {
      try {
        await phoneControl.phoneCtrlTransferRetrieveReq({
          ticket: consultationCall.ticket,
          parentTicket: consultationCall.consultationInfo.prevCallRefId,
        });
        setConsultationCall();
        setTransferNumber("");
      } catch (e) {
        toast("Failed to cancel transfer", { type: "error" });
      }
    }
  };

  const handleRejectIncoming = async () => {
    try {
      if (incomingCall) {
        setDisconnecting(true);
        await phoneControl.phoneCtrlEndCall(incomingCall.ticket);
        await handleCallEnded();
        setDisconnecting(false);
        handleCloseOverlay();
      }
    } catch (e) {
      setIncomingCall();
      throw e;
    }
  };

  const handleAnswerIncoming = async () => {
    if (incomingCall) {
      try {
        setActiveCall(incomingCall);
        await phoneControl.phoneCtrlAnswerCall({ ticket: incomingCall.ticket });

        // set a random animation
        callAnimationRef.current =
          callAnimations[Math.floor(Math.random() * callAnimations.length)];

        setIncomingCall();
      } catch (e) {
        setActiveCall();
        toast("Failed to answer call", { type: "error" });
        throw e;
      }
    }
  };

  // handle transfer to received
  useEffect(() => {
    if (eventTransferToReceived && activeCall) {
      const newActiveCall = {
        ...activeCall,
        ticket: eventTransferToReceived.ticket,
        peerId: eventTransferToReceived.peerId,
        roomId: eventTransferToReceived.roomId,
        callNumber: eventTransferToReceived.callNumber,
      };

      setActiveCall(newActiveCall);

      setActiveCallParties((callParties) => [
        ...callParties,
        {
          avatar: undefined,
          name: eventTransferToReceived.callNumber,
          callNumber: eventTransferToReceived.callNumber,
        },
      ]);

      setEventTransferToReceived();
    }
  }, [eventTransferToReceived, activeCall]);

  const defaultContext = {
    minimized,
    setMinimized,
    connecting,
    activeCall,
    consultationCall,
    activeCallParties,
    incomingCall,
    disconnecting,
    numberInvalid,
    handleMakeCall,
    handleHangup,
    blindTransferring,
    handleBlindTransfer,
    handleTransfer,
    handleTransferComplete,
    handleTransferCancel,
    transferNumber,
    handleCallAnswered,
    callAnimationRef,
    setCallDuration,
    handleAnswerIncoming,
    handleRejectIncoming,
  };

  return (
    <PhoneControlContext.Provider value={defaultContext}>
      {(connecting || activeCall) && <ActivePhoneControlCall />}
      {incomingCall && <IncomingPhoneControlCallAlert />}
      {children}
    </PhoneControlContext.Provider>
  );
};

function usePhoneControl() {
  return useContext(PhoneControlContext);
}

export { PhoneControlProvider, usePhoneControl };
