import { PiChatCircleBold } from "react-icons/pi";
import engageLogo from "../../images/engage-logo-mauve.png";
import whatsappLogo from "../../images/whatsapp-logo-green.png";

export function MessageTypeIcon({ icon = "chat" }) {
  if (icon === "engage") {
  }

  switch (icon) {
    case "engage":
      return (
        <img
          src={engageLogo}
          alt="Engage Logo"
          align="center"
          width="20px"
          style={{
            width: "20px",
            marginLeft: "15px",
            marginRight: "4px",
          }}
        />
      );
    case "whatsapp":
      return (
        <img
          src={whatsappLogo}
          alt="WhatsApp Logo"
          align="center"
          width="20px"
          style={{
            width: "20px",
            marginLeft: "15px",
            marginRight: "4px",
          }}
        />
      );
    default:
      return (
        <PiChatCircleBold
          size={30}
          style={{
            marginLeft: "15px",
            marginRight: "4px",
            color: "var(--chat-primary)",
          }}
        />
      );
  }
}
