import { Link } from "react-router-dom";
import { Label, Popup } from "semantic-ui-react";
import { getShortTimestamp } from "../../helpers/timestamp-helper";
import Avatar from "../Avatar";
import { SideScrollerSidebarItem } from "../SideScrollerLayout";
import { MessageTypeIcon } from "./MessageTypeIcon";

export function MessageListItem({
  avatar,
  chatId,
  chatUrl,
  color,
  displayName,
  hasUnread,
  icon,
  id,
  label,
  message,
  timestamp,
}) {
  return (
    <SideScrollerSidebarItem
      as={Link}
      to={chatUrl}
      active={chatId === id}
      key={chatId}
      style={{
        minHeight: "58px",
      }}
      className={`inverted ${color}`}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {avatar && (
          <Avatar
            src={avatar}
            circular
            style={{ width: "30px", marginRight: "15px" }}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
            minWidth: 0,
            width: "100%",
            gap: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {displayName.length > 18 ? (
                <Popup
                  content={displayName}
                  position="top center"
                  trigger={
                    <strong
                      style={{ color: hasUnread ? "#E85858" : "inherit" }}
                    >
                      {displayName}
                    </strong>
                  }
                />
              ) : (
                <strong style={{ color: hasUnread ? "#E85858" : "inherit" }}>
                  {displayName}
                </strong>
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div style={{ color: hasUnread ? "#E85858" : "inherit" }}>
                {getShortTimestamp(timestamp)}
              </div>
            </div>
          </div>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {message}
          </div>
          {label && (
            <Label
              size="tiny"
              className={`${color}-to`}
              style={{ width: "fit-content" }}
            >
              <strong>To:</strong> {label}
            </Label>
          )}
        </div>
        <MessageTypeIcon icon={icon} />
      </div>
    </SideScrollerSidebarItem>
  );
}
