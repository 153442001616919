import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useChannel } from "ably/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Comment } from "semantic-ui-react";
import { getEngageMessages, sendEngageMessage } from "../api/engage";
import { MessageBubble } from "../components/messaging/MessageBubble";
import MessagesContainer from "../components/messaging/MessagesContainer";
import { ReplyField } from "../components/messaging/ReplyField";
import { style } from "../components/messaging/style";
import PageLoader from "../components/PageLoader";
import { useAuth } from "../contexts/AuthContext";

function EngageChat() {
  const { id } = useParams();
  const { apiUser } = useAuth();
  const [messages, setMessages] = useState([]);
  const [scrollPosition, setScrollPosition] = useState({ id: id, pos: 0 });
  const queryClient = useQueryClient();

  useChannel(`chat:${apiUser.engage_id}:${id}`, (m) => {
    setMessages((prev) => [...prev, m.data]);
    setScrollPosition({ id: id, pos: 0 });
    document.getElementById("messagesContainer")?.scrollTo(0, 0);
  });

  const chatData = useInfiniteQuery({
    queryKey: ["engage-messages", id],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await getEngageMessages(id, {
        per_page: 30,
        page: pageParam,
      });
      // reverse data so we get the oldest chats at the top
      const reversed = res.data.data.reverse();
      res.data.data = reversed;
      return res;
    },
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1].data.meta.marker_updated) {
        queryClient.invalidateQueries({
          queryKey: ["engage-chats"],
        });
      }
      setMessages([]);
    },
    getNextPageParam: (lastPage) =>
      lastPage.data.meta.current_page < lastPage.data.meta.last_page
        ? lastPage.data.meta.current_page + 1
        : undefined,
    keepPreviousData: true,
    staleTime: 10000,
  });

  useEffect(() => {
    const messagesContainer = document.getElementById("messagesContainer");
    id === scrollPosition.id
      ? messagesContainer?.scrollTo(0, scrollPosition.pos)
      : messagesContainer?.scrollTo(0, 0);
  });

  const getSenderDisplayName = (sender) => {
    switch (sender) {
      case "user":
        return "Web User";
      case "engage":
        return "AI Chatbot";
      case apiUser.email:
        return "You";
      default:
        return sender;
    }
  };

  if (chatData.isError) {
    toast("Failed to fetch chats", { type: "error" });
    return null;
  }

  return (
    <MessagesContainer>
      {chatData.isFetching && !chatData.isFetchingNextPage ? (
        <PageLoader />
      ) : (
        <div style={style.container}>
          <div style={style.messages} id="messagesContainer">
            <Comment.Group style={style.messageGroup}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  padding: "30px 0px",
                }}
              >
                {chatData.hasNextPage && (
                  <Button
                    className="engage"
                    onClick={() => {
                      setScrollPosition({
                        id: id,
                        pos: document.getElementById("messagesContainer")
                          .scrollTop,
                      });
                      chatData.fetchNextPage();
                    }}
                    disabled={chatData.isFetching}
                    loading={chatData.isFetching}
                  >
                    Load more
                  </Button>
                )}
              </div>
              {chatData.data.pages.toReversed().map((page) => (
                <React.Fragment key={`page-${page.data.meta.current_page}`}>
                  {page.data.data.map((x) => {
                    return (
                      <MessageBubble
                        key={x.id}
                        byMe={x.sender === apiUser.email ? true : false}
                        message={x.message}
                        senderName={getSenderDisplayName(x.sender)}
                        timestamp={x.sent_at_millis}
                        highlightColor="engage"
                      />
                    );
                  })}
                </React.Fragment>
              ))}
              {messages.map((x) => {
                return (
                  <MessageBubble
                    key={x.id}
                    byMe={x.sender === apiUser.email ? true : false}
                    message={x.message}
                    senderName={getSenderDisplayName(x.sender)}
                    timestamp={x.sent_at_millis}
                    highlightColor="engage"
                  />
                );
              })}
            </Comment.Group>
          </div>

          <Reply />
        </div>
      )}
    </MessagesContainer>
  );
}

function Reply() {
  const { id } = useParams();
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState("");
  const queryClient = useQueryClient();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    await sendEngageMessage(id, message);
    queryClient.invalidateQueries({
      queryKey: ["engage-chats"],
    });

    setSending(false);
    setMessage("");
  };

  return (
    <ReplyField
      color="engage"
      handleSubmit={handleSubmit}
      message={message}
      sending={sending}
      setMessage={setMessage}
    />
  );
}

export default EngageChat;
