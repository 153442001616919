import { sdk } from "@gc/ipecs-web-sdk";
import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "@uidotdev/usehooks";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "semantic-ui-react";
import { searchContacts } from "../api/contacts";
import Topbar from "../components/Topbar";
import { useCalls } from "../contexts/CallsContext";

const style = {
  searchBox: {
    maxWidth: "596px",
  },
};

const TopbarContext = React.createContext();
const TopbarProvider = ({ children }) => {
  const { handleMakeCall } = useCalls();
  const [search, setSearch] = useState("");
  const [ipecsSearching, setIpecsSearching] = useState(false);
  const [ipecsSearchedUsers, setIpecsSearchedUsers] = useState([]);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const debouncedSearch = useDebounce(search, 500);
  const navigate = useNavigate();
  const { user } = sdk;

  const apiQuery = useQuery(
    ["search-contacts-api", { search: debouncedSearch }],
    () => {
      return search.length > 0
        ? searchContacts({ query: debouncedSearch })
        : null;
    },
    {
      keepPreviousData: true,
      cacheTime: Infinity,
      staleTime: 600000, // 10 mins
    },
  );

  useEffect(() => {
    if (debouncedSearch) {
      setIpecsSearching(true);
      user
        .searchUsers({
          term: debouncedSearch,
          field: "displayName,extensionNumber",
          scope: "all",
          limit: 15,
        })
        .then((res) => setIpecsSearchedUsers(res))
        .catch(() => setIpecsSearchedUsers([]))
        .finally(() => setIpecsSearching(false));
    }
  }, [user, debouncedSearch]);

  useEffect(() => {
    const api = apiQuery.data?.data?.data ?? [];

    const apiIpecsIds = api
      .filter((x) => x.provider === "ipecsone")
      .map((x) => x.provider_id);

    const ipecs = (ipecsSearchedUsers ?? [])
      .filter((x) => !apiIpecsIds.includes(x.id))
      .map((x) => ({
        ...x,
        provider_id: x.id,
        provider_type: x.type,
        avatar_url: x.avatar,
        first_name: x.name,
        last_name: "",
        phone_numbers: (x.callNumbers ?? []).map((cn) => ({
          video: cn?.type === "extension",
          number: cn?.value,
        })),
      }));

    const data = api.concat(ipecs).sort((a, b) => {
      const nameA = `${a.first_name} ${a.last_name}`;
      const nameB = `${b.first_name} ${b.last_name}`;
      return nameA < nameB ? -1 : 1;
    });

    setSearchedUsers(data);
  }, [apiQuery.data?.data?.data, ipecsSearchedUsers]);

  const handleChange = async (e) => {
    navigate("/");
    const _value = e.target.value;
    setSearch(_value);
  };

  const isSearching = () => apiQuery.isFetching || ipecsSearching;

  const defaultContext = {
    search,
    debouncedSearch,
    isSearching,
    searchedUsers,
  };
  return (
    <TopbarContext.Provider value={defaultContext}>
      <Topbar>
        <Input
          placeholder="Search or dial..."
          style={style.searchBox}
          fluid
          loading={isSearching()}
          icon="user"
          iconPosition="left"
          onFocus={() => navigate("/")}
          onChange={handleChange}
          value={search}
          label={
            <Button
              disabled={search.length === 0}
              onClick={() => {
                handleMakeCall(search, "audio");
                setSearch("");
              }}
              color="teal"
              style={{ borderRadius: "0 0.57rem 0.57rem 0" }}
              icon="phone"
            />
          }
          labelPosition="right"
        />
      </Topbar>
      {children}
    </TopbarContext.Provider>
  );
};

function useTopbar() {
  return useContext(TopbarContext);
}

export { TopbarProvider, useTopbar };
