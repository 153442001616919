import { sdk } from "@gc/ipecs-web-sdk";
import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "@uidotdev/usehooks";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  Comment,
  Divider,
  Icon,
  Image,
  Input,
  List,
  Popup,
} from "semantic-ui-react";
import { featuredGifs, searchGifs } from "../api/gif";
import Avatar from "../components/Avatar";
import { MessageBubble } from "../components/messaging/MessageBubble";
import MessagesContainer from "../components/messaging/MessagesContainer";
import { EventMessage } from "../components/messaging/MessageTypes";
import { ReplyField } from "../components/messaging/ReplyField";
import { style } from "../components/messaging/style";
import PageLoader from "../components/PageLoader";
import config from "../config";
import { useAuth } from "../contexts/AuthContext";
import { useChat } from "../contexts/ChatContext";
import tenor from "../images/pb-tenor.svg";

function ChatRoom() {
  const { room, user } = sdk;

  const { id } = useParams();
  const { ipecsUser } = useAuth();
  const { lastMessageReceived } = useChat();

  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [messages, setMessages] = useState([]);

  const handleLoadData = useCallback(async () => {
    const _room = await room.getRoom(id);
    Promise.all(
      _room.members.map(async (x) => {
        return user.getUserDetail(x);
      }),
    ).then((res) => setMembers(res));
    const _messages = await room.getRoomMessages({
      id: id,
      dir: "b",
      limit: 200,
    });
    _messages.messages = _messages.messages.reverse();
    setMessages(_messages);

    const _last = _messages.messages[_messages.messages.length - 1];
    if (_last) {
      room.setReadMarkers({
        id: id,
        messageId: _last.id,
      });
    }
  }, [id, room, user]);

  // initial load of data with loading states
  useEffect(() => {
    setLoading(true);
    handleLoadData().then(() => setLoading(false));
  }, [handleLoadData]);

  // reload data when new message is received
  useEffect(() => {
    handleLoadData();
  }, [handleLoadData, lastMessageReceived]);

  return (
    <MessagesContainer>
      {loading ? (
        <PageLoader />
      ) : (
        <div style={style.container}>
          <div style={style.header}>
            <List horizontal>
              {members
                .filter((x) => x !== ipecsUser.myInfo.id)
                .map((x) => (
                  <List.Item key={x.id}>
                    <Avatar src={x.avatar} circular style={style.avatar} />
                    <List.Content>
                      <List.Header>{x.name}</List.Header>
                    </List.Content>
                  </List.Item>
                ))}
            </List>
          </div>

          <Divider fitted />

          <div style={style.chatMessages}>
            <Comment.Group style={style.messageGroup}>
              {messages.messages.map((x) => (
                <Message
                  key={x.id}
                  message={x}
                  member={members.find((obj) => obj.id === x.senderId)}
                />
              ))}
            </Comment.Group>
          </div>

          <Reply />
        </div>
      )}
    </MessagesContainer>
  );
}

function Message({ message, member }) {
  switch (message.type) {
    case "m.room.create":
      return <EventMessage text={`Chat started`} />;
    case "m.room.member.start":
      return <EventMessage text={`${message.senderName} joined`} />;
    case "m.room.message.text":
    case "m.room.message.img":
    case "m.room.message.file":
      return (
        <MessageBubble
          message={message.value}
          avatar={member?.avatar}
          downloadUrl={message.downloadUrl}
          senderName={message.byMe ? "You" : message.senderName}
          senderId={message.senderId}
          isDeleted={message.isDeleted}
          messageType={message.type}
          byMe={message.byMe}
          timestamp={message.timestamp}
          highlightColor="chat"
        />
      );
    default:
      return null;
  }
}

function GifPicker({ onSelectGif }) {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const gifsQuery = useQuery(["gifs", debouncedSearch], () => {
    if (debouncedSearch.length === 0) return featuredGifs();
    return searchGifs(debouncedSearch);
  });

  return (
    <Card>
      <Card.Content>
        <Card.Header>
          <Input
            icon="search"
            placeholder="Search..."
            value={search}
            size="small"
            onChange={(e) => {
              const _value = e.target.value;
              setSearch(_value);
            }}
          />
        </Card.Header>
        <Image.Group size="tiny">
          {gifsQuery.data?.data &&
            gifsQuery.data.data.results.map((g, i) => {
              return (
                <Image
                  centered
                  style={{ height: "76px", width: "76px", cursor: "pointer" }}
                  key={`image-${i}`}
                  src={g.media_formats.nanogif.url}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectGif(g, e);
                  }}
                />
              );
            })}
        </Image.Group>
      </Card.Content>
      <Card.Content extra>
        <img style={{ width: "50%" }} src={tenor} alt="Tenor logo" />
      </Card.Content>
    </Card>
  );
}

function Reply() {
  const { id } = useParams();
  const { room } = sdk;

  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState("");
  const [gifOpen, setGifOpen] = useState(false);

  const handleSendMessage = async () => {
    if (message.length > 0) {
      try {
        setSending(true);
        await room.sendMessage({ id, message });
        setSending(false);
        setMessage("");
      } catch (e) {
        toast("Failed to send message", { type: "error" });
      }
    }
  };

  const handleOpenGif = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setGifOpen(true);
  };

  const handleCloseGif = (e) => {
    setGifOpen(false);
  };

  const handleFileChange = (e) => {
    room.sendFile({
      id: id,
      file: e.target.files[0],
    });
  };

  const handleSelectGif = async (g, e) => {
    e.stopPropagation();
    setGifOpen(false);
    const message = `[gif:${g.media_formats.gif.url}]`;
    try {
      await room.sendMessage({ id, message });
    } catch (e) {
      toast("Failed to send message", { type: "error" });
    }
  };

  return (
    <ReplyField
      color="chat"
      handleSubmit={handleSendMessage}
      message={message}
      features={["emoji"]}
      sending={sending}
      setMessage={setMessage}
    >
      {config.tenor_api_key && (
        <Popup
          content={
            <GifPicker
              onSelectGif={handleSelectGif}
              handleCloseGif={handleCloseGif}
            />
          }
          on="click"
          open={gifOpen}
          onClose={handleCloseGif}
          onOpen={handleOpenGif}
          basic
          position="top right"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0)",
            border: "none",
            boxShadow: "none",
          }}
          trigger={
            <Icon
              name="film"
              size="large"
              link
              style={style.reply.actionButton}
            />
          }
        />
      )}
      <input type="file" id="file" hidden onChange={handleFileChange} />
      <label style={style.reply.actionButton} htmlFor="file">
        <Icon link name="attach" size="large" />
      </label>
    </ReplyField>
  );
}

export default ChatRoom;
