import { useParams } from "react-router-dom";
import { Button } from "semantic-ui-react";
import useMessages from "../../hooks/useMessages";
import SideScrollerLayout, {
  SideScrollerContent,
  SideScrollerSidebar,
} from "../SideScrollerLayout";
import { MessageListItem } from "./MessageListItem";

function MessagesContainer({ children }) {
  const { id } = useParams();
  const {
    combinedThreads,
    hasMorePages,
    fetchNextPage,
    isLoadingNextPage,
    isLoading,
  } = useMessages();

  return (
    <SideScrollerLayout>
      <SideScrollerSidebar>
        {!isLoading && (
          <>
            {combinedThreads?.map((thread) => (
              <MessageListItem
                key={`${thread.type}-${thread.id}`}
                id={id}
                chatId={thread.id}
                chatUrl={thread.chatUrl}
                color={thread.color}
                icon={thread.icon}
                hasUnread={thread.hasUnread}
                avatar={thread.avatar}
                displayName={thread.displayName}
                timestamp={thread.timestamp}
                message={thread.message}
                label={thread.label}
              />
            ))}
            {combinedThreads?.length > 0 && hasMorePages && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  padding: "15px 0px",
                }}
              >
                <Button
                  color="teal"
                  onClick={() => fetchNextPage()}
                  disabled={isLoadingNextPage}
                  loading={isLoadingNextPage}
                >
                  Load more
                </Button>
              </div>
            )}
          </>
        )}
      </SideScrollerSidebar>
      <SideScrollerContent>{children}</SideScrollerContent>
    </SideScrollerLayout>
  );
}

export default MessagesContainer;
