import { Button, Form, Header, Input } from "semantic-ui-react";
import YesNoToggle from "./YesNoToggle";

function SidekickDetails({
  configured,
  sidekick,
  licenseOptions,
  onChange,
  onSubmit,
  onClickBack,
  submitting,
}) {
  const handleChangeLicenses = (e, index, quantity) => {
    onChange(e, {
      name: "licenses",
      value: sidekick.licenses.map((l, i) =>
        i === index ? { ...l, quantity: quantity.value } : l,
      ),
    });
  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Field>
          <label>Is this a Sidekick order?</label>
          <YesNoToggle value={sidekick.required} onChange={onChange} />
        </Form.Field>

        {sidekick.required && (
          <>
            {!configured && (
              <>
                <Form.Field>
                  <label>Admin email address</label>
                  <p>
                    An initial user will be created as an admin. This user can
                    then add and assign Sidekick licences to other users.
                  </p>
                  <Input
                    type="email"
                    name="admin_email"
                    onChange={onChange}
                    value={sidekick.admin_email}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Admin extension</label>
                  <Input
                    type="text"
                    name="admin_extension"
                    onChange={onChange}
                    value={sidekick.admin_extension}
                  />
                </Form.Field>
              </>
            )}

            <Header as="h3" dividing>
              Licenses
            </Header>
            {licenseOptions.map((l, i) => (
              <Form.Field inline key={`license-option-${i}`}>
                <label>{l.name}</label>
                <Input
                  type="number"
                  name="quantity"
                  onChange={(e, data) => {
                    handleChangeLicenses(e, i, data);
                  }}
                  value={sidekick.licenses[i]?.quantity ?? 0}
                />
              </Form.Field>
            ))}
          </>
        )}

        <Button.Group>
          <Button onClick={onClickBack}>Back</Button>
          <Button.Or />
          <Button loading={submitting} primary>
            Next
          </Button>
        </Button.Group>
      </Form>
    </>
  );
}

export default SidekickDetails;
