import axios from "axios";
import config from "../config";

export function getWhatsappThreads(filters = {}) {
  return axios.get(`${config.api_url}/whatsapp/threads`, {
    params: filters,
  });
}

export function getWhatsappMessages(threadId, filters = {}) {
  return axios.get(`${config.api_url}/whatsapp/messages/${threadId}`, {
    params: filters,
  });
}

export function sendWhatsappMessage(threadId, message) {
  return axios.post(`${config.api_url}/whatsapp/messages/${threadId}`, {
    message: message,
  });
}

export function downloadWhatsappMedia(messageId) {
  return axios.get(`${config.api_url}/whatsapp/media/${messageId}`, {});
}

export function uploadWhatsappMedia(threadId, file) {
  let formData = new FormData();
  formData.append("media", file);

  return axios.post(
    `${config.api_url}/whatsapp/messages/${threadId}/media`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
}

export function getWhatsappConfig() {
  return axios.get(`${config.api_url}/whatsapp/config`);
}

export function updateWhatsappConfig(body) {
  return axios.post(`${config.api_url}/whatsapp/config`, body);
}

export function addWhatsappNumber(body) {
  return axios.post(`${config.api_url}/whatsapp/config/add-number`, body);
}

export function removeWhatsappNumber(id) {
  return axios.patch(`${config.api_url}/whatsapp/config/remove-number/${id}`);
}
