import { useInfiniteQuery } from "@tanstack/react-query";
import { getEngageChats } from "../api/engage";

const useEngageThreads = ({ enabled = false }) => {
  return useInfiniteQuery({
    queryKey: ["engage-chats"],
    queryFn: async ({ pageParam = 1 }) => {
      return await getEngageChats({ page: pageParam });
    },
    getNextPageParam: (lastPage) =>
      lastPage.data.meta.current_page < lastPage.data.meta.last_page
        ? lastPage.data.meta.current_page + 1
        : undefined,
    keepPreviousData: true,
    cacheTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: enabled,
  });
};

export default useEngageThreads;
