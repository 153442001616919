import { sdk } from "@gc/ipecs-web-sdk";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Grid,
  GridColumn,
  Header,
  Icon,
  Image,
  List,
  Modal,
} from "semantic-ui-react";
import { removeFavourite, setFavourite } from "../api/contacts";
import { useAuth } from "../contexts/AuthContext";
import { useCalls } from "../contexts/CallsContext";
import Avatar from "./Avatar";
import ContactWebLinkIcon from "./ContactWebLinkIcon";
import PresenceIndicator from "./PresenceIndicator";

const ContactCard = ({
  data,
  provider = "ipecsone",
  limit,
  fluid,
  cardType = "list",
}) => {
  const { room } = sdk;
  const { hasFeature, isPhoneControl } = useAuth();

  const queryClient = useQueryClient();

  const { hasActiveCall, handleMakeCall } = useCalls();
  const [isFavourite, setIsFavourite] = useState(data.favourite);
  const [startingChat, setStartingChat] = useState(false);
  const navigate = useNavigate();

  const showMoreButton = () => data.phone_numbers.length > 2;

  const getAvatar = () => {
    if (
      [
        "google",
        "hubspot",
        "microsoft",
        "monday",
        "vincere",
        "salesforce",
        "zoho",
        "zoho-desk",
      ].includes(provider.value)
    ) {
      return <Image src={provider.logo} style={{ width: "45px" }} />;
    } else {
      return (
        <Avatar src={data.avatar_url} circular style={{ width: "45px" }} />
      );
    }
  };

  const handleChat = async () => {
    try {
      setStartingChat(true);
      const res = await room.createRoom({
        roomType: "Chat",
        userIds: [data.provider_id],
        name: data.name,
      });
      navigate(`/messages/chat/${res.roomId}`);
    } catch (e) {
      setStartingChat(false);
      toast("Failed to start chat", { type: "error" });
    }
  };

  const handleToggleFavourite = async () => {
    try {
      if (!isFavourite) {
        setIsFavourite(true);
        await setFavourite(data.id);
        queryClient.removeQueries({
          queryKey: ["contacts"],
        });
      } else {
        setIsFavourite(false);
        await removeFavourite(data.id);
        queryClient.removeQueries({
          queryKey: ["contacts"],
        });
      }
    } catch (e) {
      toast("Unable to change favourite contact", { type: "error" });
      setIsFavourite(!isFavourite);
    }
  };

  return (
    <Card fluid={fluid}>
      <Card.Content>
        <Grid>
          <GridColumn
            width={4}
            style={{
              display: "flex",
              alignItems: cardType === "list" ? "center" : "left",
              justifyContent: "space-between",
              flexDirection: "column",
              minHeight: "175px",
              maxHeight: "175px",
            }}
          >
            <div>
              {getAvatar()}
              {data.provider_type === "wuc" && (
                <PresenceIndicator
                  style={{ position: "absolute", top: "46px", left: "46px" }}
                  userId={data.provider_id}
                  user={data}
                  size="large"
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                alignItems: cardType === "list" ? "center" : "left",
                justifyContent: "space-between",
                paddingTop: "10px",
              }}
            >
              <div>
                {data.web_link && (
                  <ContactWebLinkIcon
                    onClick={() => {
                      window.open(data.web_link, "_blank");
                    }}
                  />
                )}
              </div>
              <div style={{ align: "center" }}>
                {data.can_favourite && (
                  <Icon
                    name={isFavourite ? "star" : "star outline"}
                    size="large"
                    onClick={handleToggleFavourite}
                    style={{ color: isFavourite ? "gold" : "white" }}
                  />
                )}
              </div>
            </div>
          </GridColumn>
          <GridColumn width={12}>
            <Header>{`${data.first_name} ${data.last_name}`}</Header>
            <List divided verticalAlign="middle">
              {hasFeature(["chat"]) && data.provider_type === "wuc" && (
                <List.Item>
                  <List.Content floated="right">
                    <Button.Group size="mini">
                      <Button
                        icon
                        color="violet"
                        onClick={handleChat}
                        loading={startingChat}
                        disabled={startingChat}
                      >
                        <Icon name="chat" />
                      </Button>
                    </Button.Group>
                  </List.Content>
                  <List.Header>Chat</List.Header>
                </List.Item>
              )}
              {data.provider !== "ipecsone" &&
                (data.emails ?? []).slice(0, limit ? 1 : 12).map((x) => (
                  <List.Item key={x}>
                    <List.Content floated="right">
                      <Button.Group size="mini">
                        <Button
                          icon
                          color="blue"
                          onClick={() => window.open(`mailto:${x}`)}
                        >
                          <Icon name="mail" />
                        </Button>
                      </Button.Group>
                    </List.Content>
                    <List.Header
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {x}
                    </List.Header>
                  </List.Item>
                ))}
              {(data.phone_numbers ?? []).slice(0, limit ? 2 : 12).map((x) => (
                <List.Item key={x.number}>
                  <List.Content floated="right">
                    <Button.Group size="mini">
                      {x.video &&
                        !isPhoneControl &&
                        hasFeature(["video_calls"]) && (
                          <>
                            <Button
                              icon
                              color="purple"
                              disabled={hasActiveCall()}
                              onClick={() => handleMakeCall(x.number, "video")}
                            >
                              <Icon name="video" />
                            </Button>
                          </>
                        )}
                      <Button
                        icon
                        color="teal"
                        disabled={hasActiveCall()}
                        onClick={() => handleMakeCall(x.number, "audio")}
                      >
                        <Icon name="phone" />
                      </Button>
                    </Button.Group>
                  </List.Content>
                  <List.Header>{x.number}</List.Header>
                  <List.Content size="small">{x.type}</List.Content>
                </List.Item>
              ))}

              {limit && showMoreButton() ? (
                <List.Item>
                  <List.Content floated="right">
                    <Button.Group size="mini">
                      <UserModal
                        trigger={
                          <Button icon color="grey">
                            <Icon name="search" />
                          </Button>
                        }
                      >
                        <ContactCard
                          data={data}
                          provider={provider}
                          fluid={true}
                          cardType="modal"
                        />
                      </UserModal>
                    </Button.Group>
                  </List.Content>
                  <List.Header>More info</List.Header>
                </List.Item>
              ) : null}
            </List>
          </GridColumn>
        </Grid>
      </Card.Content>
    </Card>
  );
};

function UserModal({ trigger, children }) {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      basic
      size="tiny"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
    >
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ContactCard;
